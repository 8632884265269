import { Link, useNavigate } from "react-router-dom";
import styles from "../../../styles/component-styles/admin/layout.module.scss";
import home_ic from "../../../assets/icons/home.svg";
import song_ic from "../../../assets/icons/song.svg";
import video_ic from "../../../assets/icons/video.svg";
import playlist_ic from "../../../assets/icons/playlist.svg";
import paytip_ic from "../../../assets/icons/paytip.svg";
import logout_ic from "../../../assets/icons/logout.svg";
import reports_ic from "../../../assets/icons/reports.svg";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectTab } from "../../../redux/slice/ui/ui-slice";
import upload_ic from "../../../assets/icons/upload.svg";
import ads_ic from "../../../assets/icons/ads.svg";
import { logoutUser } from "../../../redux/slice/auth/auth-slice";
import { constants } from "../../../constants";
import { selectLanguage } from "../../../redux/slice/language/laguage.slice";
import close_ic from "../../../assets/icons/close-ic.svg";
import logo from "../../../assets/icons/logo-white.svg";
import legal_ic from "../../../assets/icons/legal.svg";
export function Sidebar() {
  const active = useSelector(selectTab);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(logoutUser({}));
    navigate("/");
  };
  const language = useSelector(selectLanguage);
  return (
    <div className={styles.sidebar}>
      <div className={styles.top}>
        <div className={styles.logo}>
          <h1
            onClick={() => {
              navigate("/admin");
            }}
          >
            <img src={logo} />
          </h1>
        </div>
        <hr />
        <ul className={styles.menu}>
          <li
            className={`${styles.menuItem} ${
              active.includes("Upload Music") ? styles.active : ""
            }`}
            onClick={() => {
              navigate("/admin");
            }}
          >
            <img src={upload_ic} />
            <Link>{constants[language].upload_music}</Link>
          </li>
          <li
            className={`${styles.menuItem} ${
              active.includes("Upload Video") ? styles.active : ""
            }`}
            onClick={() => {
              window.open(
                "https://www.strato.de/apps/CustomerService",
                "_blank"
              );
            }}
          >
            <img src={upload_ic} />
            <Link>{constants[language].upload_video}</Link>
          </li>
          <li
            className={`${styles.menuItem} ${
              active.includes("Songs") ? styles.active : ""
            }`}
            onClick={() => {
              navigate("/admin/songs");
            }}
          >
            <img src={song_ic} />
            <Link>{constants[language].songs}</Link>
          </li>
          <li
            className={`${styles.menuItem} ${
              active.includes("Playlist") ? styles.active : ""
            }`}
            onClick={() => {
              navigate("/admin/playlist");
            }}
          >
            <img src={playlist_ic} />
            <Link>{constants[language].playlist}</Link>
          </li>
          <li
            className={`${styles.menuItem} ${
              active.includes("Videos") ? styles.active : ""
            }`}
            onClick={() => {
              navigate("/admin/videos");
            }}
          >
            <img src={video_ic} />
            <Link>{constants[language].videos}</Link>
          </li>

          <li
            className={`${styles.menuItem} ${
              active.includes("Ads") ? styles.active : ""
            }`}
            onClick={() => {
              navigate("/admin/ads");
            }}
          >
            <img src={ads_ic} />
            <Link>{constants[language].ads}</Link>
          </li>
          <li
            className={`${styles.menuItem} ${
              active.includes("Legal Notice") ? styles.active : ""
            }`}
            onClick={() => {
              navigate("/admin/legal-notice");
            }}
          >
            <img src={legal_ic} />
            <Link>{constants[language].legal_notice}</Link>
          </li>
          <li
            className={`${styles.menuItem} ${
              active.includes("Reports") ? styles.active : ""
            }`}
            onClick={() => {
              navigate("/admin/reports");
            }}
          >
            <img src={reports_ic} />
            <Link>{constants[language].reports}</Link>
          </li>
        </ul>
      </div>
      <div className={styles.bottom}>
        <div onClick={logout}>
          <img src={logout_ic} />
          <span className={styles.logout}> {constants[language].logout} </span>
        </div>
      </div>
    </div>
  );
}
export function MobileSidebar({ isOpen, setOpen }) {
  const active = useSelector(selectTab);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const language = useSelector(selectLanguage);
  const ref = useRef(null);
  useEffect(() => {
    if (isOpen) {
      ref.current.style.left = "0";
    } else {
      ref.current.style.left = "-100%";
    }
  }, [isOpen]);
  const logout = () => {
    dispatch(logoutUser({}));
    navigate("/");
    setOpen(false);
  };
  return (
    <div className={styles.mobileSidebar} ref={ref}>
      <div className={styles.top}>
        <div className={styles.logo}>
          <h1
            onClick={() => {
              navigate("/admin");
              setOpen(false);
            }}
          >
            {constants[language].powered_by}
          </h1>
          <span
            onClick={() => {
              setOpen(false);
            }}
          >
            <img src={close_ic} />
          </span>
        </div>
        <hr />
        <ul
          className={styles.menu}
          onClick={() => {
            setOpen(false);
          }}
        >
          <li
            className={`${styles.menuItem} ${
              active.includes("Upload Music") ? styles.active : ""
            }`}
            onClick={() => {
              navigate("/admin");
            }}
          >
            <img src={upload_ic} />
            <Link>{constants[language].upload_music}</Link>
          </li>
          <li
            className={`${styles.menuItem} ${
              active.includes("Upload Video") ? styles.active : ""
            }`}
            onClick={() => {
              window.open(
                "https://www.strato.de/apps/CustomerService",
                "_blank"
              );
            }}
          >
            <img src={upload_ic} />
            <Link>{constants[language].upload_video}</Link>
          </li>

          <li
            className={`${styles.menuItem} ${
              active.includes("Songs") ? styles.active : ""
            }`}
            onClick={() => {
              navigate("/admin/songs");
            }}
          >
            <img src={song_ic} />
            <Link>{constants[language].songs}</Link>
          </li>
          <li
            className={`${styles.menuItem} ${
              active.includes("Playlist") ? styles.active : ""
            }`}
            onClick={() => {
              navigate("/admin/playlist");
            }}
          >
            <img src={playlist_ic} />
            <Link>{constants[language].playlist}</Link>
          </li>
          <li
            className={`${styles.menuItem} ${
              active.includes("Videos") ? styles.active : ""
            }`}
            onClick={() => {
              navigate("/admin/videos");
            }}
          >
            <img src={video_ic} />
            <Link>{constants[language].videos}</Link>
          </li>

          <li
            className={`${styles.menuItem} ${
              active.includes("Ads") ? styles.active : ""
            }`}
            onClick={() => {
              navigate("/admin/ads");
            }}
          >
            <img src={ads_ic} />
            <Link>{constants[language].ads}</Link>
          </li>
          <li
            className={`${styles.menuItem} ${
              active.includes("Legal Notice") ? styles.active : ""
            }`}
            onClick={() => {
              navigate("/admin/legal-notice");
            }}
          >
            <img src={legal_ic} />
            <Link>{constants[language].legal_notice}</Link>
          </li>
          <li
            className={`${styles.menuItem} ${
              active.includes("Reports") ? styles.active : ""
            }`}
            onClick={() => {
              navigate("/admin/reports");
            }}
          >
            <img src={reports_ic} />
            <Link>{constants[language].reports}</Link>
          </li>
        </ul>
      </div>
      <div className={styles.bottom}>
        <div onClick={logout}>
          <img src={logout_ic} />
          <span className={styles.logout}> {constants[language].logout} </span>
        </div>
      </div>
    </div>
  );
}
