import React from "react";
import styles from "../../../styles/component-styles/general/grid.module.scss";
import { Card } from "./card"; // Ensure Card supports rendering thumbnails
import no_data from "../../../assets/images/no_data.svg";
import loader from "../../../assets/gifs/loader.webp";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../redux/slice/language/laguage.slice";
import { constants } from "../../../constants";
import { useState, useEffect } from "react";
import { WatchVideo } from "../../admin/modals/watchVideo";
import { useInView } from "react-intersection-observer";
import { useSearchParams } from "react-router-dom";
import { SelectedVideoAction } from "./selected-video-action";
import { VideoPlayer } from "../../admin/modals/VideoPlayerSimple";

export function Grid({
  videos,
  setCurrentPage,
  currentPage,
  totalPages,
  isAdmin,
  searchTerm,
  deleteVideos,
}) {
  const language = useSelector(selectLanguage);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [newVideos, setNewVideos] = useState([]);
  const [selectedNewVideo, setSelectedNewVideo] = useState(null);
  const [ref, inView] = useInView({ triggerOnce: false });

  const fetchAllVideos = async () => {
    const API_URL = `${
      process.env.REACT_APP_BACKEND_URL || "http://felix-entertainment.de"
    }/all_videos`;

    try {
      const res = await fetch(API_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (!res.ok) {
        throw new Error("Network response was not ok " + res.statusText);
      }

      const data = await res.json();
      setNewVideos(data.videos);
    } catch (error) {
      console.error("Error fetching videos:", error);
      throw error;
    }
  };

  useEffect(() => {
    fetchAllVideos();
  }, []);

  const onClick = (video) => {
    setSelectedNewVideo(video);
  };

  const onClose = () => {
    setSearchParams({});
    setSelectedNewVideo(null);
  };

  const onVideoSelect = (_id) => {
    setSelectedVideos([...selectedVideos, _id]);
  };

  const onVideoDeselect = (_id) => {
    setSelectedVideos(selectedVideos.filter((id) => id !== _id));
  };

  const onCancel = () => {
    setSelectedVideos([]);
  };

  const onDelete = () => {
    deleteVideos(Array.from(selectedVideos));
    setSelectedVideos([]);
  };

  return (
    <React.Fragment>
      <div className={styles.grid}>
        <WatchVideo
          isOpen={searchParams.get("videoId") != null}
          videoId={searchParams.get("videoId")}
          onClose={onClose}
          isAdmin={isAdmin}
        />

        {/* Original Videos Section */}
        {/* {videos &&
          videos.length > 0 &&
          videos.map((video, index) => (
            <span key={index} ref={index === videos.length - 1 ? ref : null}>
              <Card
                title={video.title}
                description={video.description}
                videoUrl={video.videoUrl}
                createdAt={video.createdAt}
                view={video.view}
                onClick={onClick}
                isAnySelected={selectedVideos.length > 0}
                isAdmin={isAdmin}
                onSelect={onVideoSelect}
                onSelectRemove={onVideoDeselect}
                key={video._id}
                _id={video._id}
                isSelected={selectedVideos.includes(video._id)}
              />
            </span>
          ))} */}

        {!videos && (
          <div className={styles.noData}>
            <img src={loader} />
            <p>{constants[language].loading}</p>
          </div>
        )}
        {videos && videos.length === 0 && (
          <div className={styles.noData}>
            <img src={no_data} />
            <p>{constants[language].no_data}</p>
          </div>
        )}
        {selectedVideos.length > 0 && (
          <div className={styles.overlay}>
            <SelectedVideoAction
              number={selectedVideos.length}
              onDelete={onDelete}
              onCancel={onCancel}
            />
          </div>
        )}
      </div>
      <div className="new_videoThumbnails">
        {newVideos && newVideos.length > 0 ? (
          <div className="new_gridContainer">
            {newVideos
              .filter((video) =>
                video.decodedName
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
              )
              .map((video) => (
                <div
                  key={video.name}
                  className="new_videoThumbnail"
                  onClick={() => onClick(video)}
                >
                  <img
                    src={video.thumbnailUrl}
                    alt={video.decodedName}
                    className="new_thumbnail"
                  />
                  <p className="new_videoTitle">
                    <div>{video.decodedName.split("-")[0]}</div>

                    {video.decodedName.split("-")[1]}
                  </p>
                </div>
              ))}
          </div>
        ) : (
          <div className="new_noData">
            {/* <img src={loader} alt="Loading..." />
      <p>{constants[language].loading}</p> */}
          </div>
        )}

        {selectedNewVideo && (
          <VideoPlayer
            isAdmin={isAdmin}
            videoName={selectedNewVideo.name}
            videoUrl={selectedNewVideo.url}
            isOpen={selectedNewVideo.url}
            onClose={onClose}
            // setOpen={setOpen}
          />
        )}
      </div>
    </React.Fragment>
  );
}
