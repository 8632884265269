import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateTab } from "../../../redux/slice/ui/ui-slice";
import styles from "../../../styles/page-styles/admin/upload-video.module.scss";
import { UploadVideo as Upload } from "../../../components/admin/upload/upload-video";
import { Grid } from "../../../components/general/grid";
import { videos } from "./constant";
import {
  deleteVideosAsync,
  getTopVideosAsync,
  selectTopVideos,
} from "../../../redux/slice/video/video-slice";
export function UploadVideo() {
  const dispatch = useDispatch();
  const videos = useSelector(selectTopVideos);
  useEffect(() => {
    dispatch(updateTab({ tab: "Upload Video" }));
    if (!videos) {
      dispatch(getTopVideosAsync({}));
    }
  }, []);

  const deleteVideos = (selectedVideos) => {
    dispatch(deleteVideosAsync({ ids: selectedVideos }));
  };
  return (
    <div className={styles.uploadVideo}>
      <Upload />
      <div className={styles.table}>
        <Grid videos={videos} isAdmin={false} deleteVideos={deleteVideos} />
      </div>
    </div>
  );
}
